body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: auto;
}

::-webkit-scrollbar {
	width: 5px;
	height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #228be6;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #228be6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #228be6;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.not-visible {
	visibility: hidden;
}
