.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.rbc-show-more {
	margin-left: 10px !important;
	color: #228be6 !important;
}

/* for react-big-calendar show more detail popup */
.rbc-overlay {
	z-index: 299 !important;
}

.rbc-toolbar {
	flex-direction: row-reverse !important;
}

.rbc-btn-group,
.rbc-toolbar-label {
	width: 50% !important;
	display: flex !important;
	justify-content: end !important;
	padding: unset !important;
}

.rbc-toolbar-label {
	margin-right: -82px;
	color: #3f4254;
	font-size: 20px;
}

.rbc-month-view {
	border-radius: 6px;
}

.rbc-header {
	color: #3f4254;
	font-family: 500;
}

.hovered-title {
	cursor: pointer !important;
}

.hovered-title:hover {
	color: #228be6;
}

.gray-background {
	background-color: #f1eeee94 !important;
}

.mantine-1jbrfp {
	margin-right: 0 !important;
}

.mantine-5xkc86,
.mantine-dl1tja:focus {
	border: none;
}

.demo {
	.mantine-Modal-body {
		background-color: #f0f3f4;
		max-height: calc(100vh - (16vh * 2)) !important;
		overflow-y: auto !important;
	}
}

.icon-position {
	position: absolute;
	top: 0;
	right: 10px;
	background-color: #ffffff;
	padding: 4px 8px;
	border-radius: 15px;
}

.button {
	&:disabled,
	&[data-disabled] {
		background-color: #f1faff;
	}
}

.dark-mode {
	.rbc-header {
		color: #b5b7c8;
		border-bottom: 1px solid #26272f;
	}
	.rbc-off-range-bg {
		background-color: #25262b;
	}
	.rbc-today {
		background-color: #1f212a;
	}
	.rbc-show-more {
		background-color: unset;
	}
	.rbc-month-view,
	.rbc-month-row + .rbc-month-row {
		border: 1px solid #26272f;
	}
	.rbc-day-bg + .rbc-day-bg,
	.rbc-header + .rbc-header {
		border-left: 1px solid #26272f;
	}

	.toolbar-btn {
		border: 1px solid #26272f;
		color: #c1c2c5;
	}

	.rbc-calendar {
		color: #c1c2c5;
	}
}
